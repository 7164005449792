export enum RoleEnum {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_MONITORING = 'ROLE_MONITORING',
  ROLE_SLOT = 'ROLE_SLOT',
  ROLE_TERMINAL = 'ROLE_TERMINAL',
  ROLE_MONITORING_RELOAD_BLOCK = 'ROLE_MONITORING_RELOAD_BLOCK',
  ROLE_MONITORING_RELOAD = 'ROLE_MONITORING_RELOAD',
  ROLE_MONITORING_RELOAD_BLOCK_SDHD = 'ROLE_MONITORING_RELOAD_BLOCK_SDHD',
  ROLE_ADMIN_VNO = 'ROLE_ADMIN_VNO',
  ROLE_ADMIN_CUENTAS = 'ROLE_ADMIN_CUENTAS',
  ROLE_TECHNICAL_SUPPORT = 'ROLE_TECHNICAL_SUPPORT',
  ROLE_INSTALLER = 'ROLE_INSTALLER'
}

import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { PlatformOperationsError } from './platform-operations-error-response.error';
import { ErrorService } from './error.service';
import { ModalServiceFromComponentComponent } from '@shared/components/modal/modal-service-from-component/modal-service-from-component.component';
import { environment } from '@environments/environment';
import { ErrorExplicitPlatform } from './error-explicit-platform.interface';
import { AuthenticationService } from '@services/authentication.service/authentication.service';
import { Router } from '@angular/router';
import { ClientAuthError } from '@azure/msal-common';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  bsModalRef?: BsModalRef;
  modalService?: BsModalService;
  static readonly ERROR_CODE_K = 100000;
  static readonly ERROR_CODE_H = 200000;
  static readonly ERROR_CODE_T = 300000;
  static readonly ERROR_CODE_A = 400000;
  static readonly ERROR_CODE_C = 500000;
  static readonly ERROR_CODE_AZURE_USER = 105002;
  static readonly ERROR_CODE_AZURE_DOMAIN = 105003;

  constructor(
    private injector: Injector,
    private translate: TranslateService,
    private authService: AuthenticationService
  ) {}

  private openModalWithComponent(text: string) {
    const initialState = { list: [text], title: '' };
    this.bsModalRef = this.modalService?.show(ModalServiceFromComponentComponent, { initialState });

    this.bsModalRef?.content.onCloseSubject.subscribe(() => {
      this.authService.logout();
    });
  }

  handleError(error: Error | HttpErrorResponse) {
    const toastr = this.injector.get(ToastrService);
    this.modalService = this.injector.get(BsModalService);
    const errorService = this.injector.get(ErrorService);
    const router = this.injector.get(Router);
    if (error instanceof Error) {
      const currError = error as any;
      error = currError.rejection ? currError.rejection : error;
    }

    switch (error.constructor) {
      case ClientAuthError: {
        localStorage.removeItem('msal.account.keys');
        this.authService.logout();
        break;
      }
      case PlatformOperationsError: {
        const err = error as PlatformOperationsError;
        this.translate.get(err.message).subscribe(() => {
          toastr.error(this.translate.instant(err.message), err.title, err.override);
        });
        break;
      }
      case HttpErrorResponse: {
        // Server error
        const err = error as HttpErrorResponse;
        if (504 === err.status) {
          this.translate.get('TOAST.MESSAGE.ERROR_504').subscribe(() => {
            toastr.error(this.translate.instant('TOAST.MESSAGE.ERROR_504'), 'Error', { timeOut: 5000 });
          });
        }
        if ([401, 403].indexOf(err.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          // this.authenticationService.logout();
          // location.reload(true);
          // this.openModalWithComponent(err.error.data);
          // }
          errorService.setError(err);
        }

        // if (HttpStatusCode.Unauthorized === err.status && err.error.data !== 'Bad credentials')
        if (HttpStatusCode.Unauthorized === err.status && err?.error?.data !== 'Bad credentials') {
          const modalMsg = err.error
            ? err.error.message || '' + err.error.data
            : this.translate.instant('USERS.SESSION.EXPIRED');
          if (localStorage.getItem('currentUser')) {
            this.authService.logout();
          }
          if (err.error?.code === CustomErrorHandler.ERROR_CODE_AZURE_USER) {
            router.navigate(['/axess/login-axess'], { queryParams: { error: 'user' } });
          } else if (err.error?.code === CustomErrorHandler.ERROR_CODE_AZURE_DOMAIN) {
            router.navigate(['/axess/login-axess'], { queryParams: { error: 'domain' } });
          } else {
            this.openModalWithComponent(modalMsg);
          }
        }
        if (400 === err.status || 409 === err.status) {
          if (
            err &&
            err.error &&
            err.error.code &&
            (err.error.code === CustomErrorHandler.ERROR_CODE_K ||
              err.error.code === CustomErrorHandler.ERROR_CODE_H ||
              err.error.code === CustomErrorHandler.ERROR_CODE_A ||
              err.error.code === CustomErrorHandler.ERROR_CODE_T ||
              err.error.code === CustomErrorHandler.ERROR_CODE_C)
          ) {
            toastr.error(this.prepareExplicitErrorPlatform(err.error), 'Error', { timeOut: 10000 });
          } else if (err && err.error && _.isArray(err.error.data)) {
            err.error.data.forEach((element: any) => {
              toastr.error(element.message, element.field, { timeOut: 5000 });
            });
          } else {
            if (err && err.error && err.error.data) {
              this.translate.get(err.error.data).subscribe(() => {
                toastr.error(this.translate.instant(err.error.data), 'Error', { timeOut: 5000 });
              });
            } else {
              this.translate.get('TOAST.MESSAGE.ERROR_500').subscribe(() => {
                toastr.error(this.translate.instant('TOAST.MESSAGE.ERROR_500'), 'Error', { timeOut: 5000 });
              });
            }
          }
        }
        if (500 === err.status) {
          this.translate.get('TOAST.MESSAGE.ERROR_500').subscribe(() => {
            toastr.error(this.translate.instant('TOAST.MESSAGE.ERROR_500'), 'Error', { timeOut: 5000 });
          });
        }
        if (503 === err.status) {
          if (err.error.code) {
            this.translate.get(`TOAST.MESSAGE.ERROR_503_${err.error.code}`).subscribe(() => {
              toastr.error(this.translate.instant(`TOAST.MESSAGE.ERROR_503_${err.error.code}`), 'Error', {
                timeOut: 5000
              });
            });
          }
        }
        if (0 === err.status) {
          this.translate.get('TOAST.MESSAGE.ERROR_0').subscribe(() => {
            toastr.error(this.translate.instant('TOAST.MESSAGE.ERROR_0'), 'Error', { timeOut: 5000 });
          });
        }
        if (HttpStatusCode.Locked === err.status) {
          errorService.setError(err);
        }
        break;
      }
      default: {
        if (error.name === 'ChunkLoadError') {
          console.error(error);
          window.location.reload();
        }

        this.translate.get('TOAST.MESSAGE.ERROR_500').subscribe(() => {
          toastr.error(this.translate.instant('TOAST.MESSAGE.ERROR_500'), 'Error', { timeOut: 5000 });
        });
      }
    }
    if (!environment.production) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  private prepareExplicitErrorPlatform(error: any) {
    const errorExplicitPlatform = error['explicit-platform-error'] as ErrorExplicitPlatform;
    const responseTranslate: string = this.translate.instant(`${errorExplicitPlatform.code}`);
    const code = errorExplicitPlatform.code ? '. \n  code: ' + responseTranslate : '';
    return errorExplicitPlatform.platform + ': ' + errorExplicitPlatform.message + ': ' + code;
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromActions from '../store/login.actions';
import { TranslateService } from '@ngx-translate/core';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { JWT } from '@interceptors/jwt.interceptor/jwt.interface';
import { filter } from 'rxjs/operators';
import { AuthenticationService } from '@services/authentication.service/authentication.service';
import { DomainService } from '@shared/services/domain/domain.service';
import { DomainsEnum } from '@shared/models/common/domains.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-oauth2',
  templateUrl: './login-oauth2.component.html',
  styleUrls: ['./login-oauth2.component.scss']
})
export class LoginOauth2Component implements OnInit {
  constructor(
    private authenticationService: AuthenticationService,
    private authServiceMsal: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadcastService: MsalBroadcastService,
    private store: Store,
    private router: Router,
    private readonly translate: TranslateService
  ) {
    if (DomainService.getDomain() === DomainsEnum.HISPASAT) {
      this.router.navigate(['/login']);
    }
    this.prepareMsal();
  }

  ngOnInit() {
    if (this.authServiceMsal.instance.getActiveAccount()) {
      if (!this.authenticationService.isLoggedOauth2()) {
        this.logoutMsal();
      } else if (!this.authenticationService.isLogged()) {
        this.getBackendInfo();
      } else {
        this.logoutMsal();
      }
      if (this.authenticationService.isLoggedOauth2() && this.authenticationService.isLogged()) {
        this.router.navigate(['/dashboard']);
      }
    }
  }

  private getBackendInfo() {
    if (this.authenticationService.isLoggedOauth2()) {
      this.store.dispatch(fromActions.tokenAzureStart());
    }
  }

  private prepareMsal() {
    this.authServiceMsal.instance.enableAccountStorageEvents();
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.SSO_SILENT_SUCCESS
        )
      )
      .subscribe((result: EventMessage) => {
        const payloadRes: any = result.payload;
        const token: JWT = {};
        const payload = result.payload as AuthenticationResult;
        this.authServiceMsal.instance.setActiveAccount(payload.account);
        if (payloadRes.idToken) {
          token.access_token = payloadRes.idToken;
          this.authenticationService.authOatuh2(token);
          this.getBackendInfo();
        }
      });

    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.HANDLE_REDIRECT_END))
      .subscribe(() => {
        if (!this.authServiceMsal.instance.getActiveAccount()) {
          this.loginMsal();
        }
      });

    // this.msalBroadcastService.msalSubject$.subscribe((result: EventMessage) => {
    //   console.log(result);
    // });
  }

  loginMsal(userFlowRequest?: RedirectRequest | PopupRequest) {
    this.authServiceMsal.loginRedirect(userFlowRequest);
  }

  logoutMsal() {
    this.authServiceMsal.logoutRedirect();
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { concatMap, first, map, switchMap } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { Token } from '@pages/login/interfaces/token.interface';
import { clientTypeWeight } from '@shared/models/client/client-type-weight';
import { roleWightUser } from '@shared/models/users/role-weight-user';
import { ClientTypeEnum } from '@shared/models/client/client-type.enum';
import { RoleEnum } from '@shared/models/users/role.enum';
import { BaseDto } from '@shared/models/data-base/base-dto.model';
import { User } from '@shared/models/users/user.model';
import { HttpRequestCache } from '@interceptors/cache.interceptor/cache.interceptor';
import { ClientDomainEnum } from '@shared/models/client/client-domain.enum';
import { JWT } from '@interceptors/jwt.interceptor/jwt.interface';
import * as _ from 'lodash';
import { DomainService } from '@shared/services/domain/domain.service';
import { DomainsEnum } from '@shared/models/common/domains.enum';
import { JwtHelperService } from '@auth0/angular-jwt';
const jwtHelper = new JwtHelperService();
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private readonly currentUserSubject: BehaviorSubject<User>;
  private readonly currentJwtSubject: BehaviorSubject<JWT>;
  private _refreshTokenPeriod = 300000;

  constructor(private http: HttpClient, private router: Router, private httpRequestCache: HttpRequestCache) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser') || '{}'));
    this.currentJwtSubject = new BehaviorSubject<JWT>(JSON.parse(localStorage.getItem('token') || '{}'));
  }

  get jwt(): BehaviorSubject<JWT> {
    return this.currentJwtSubject;
  }

  get currentUser(): BehaviorSubject<User> {
    return this.currentUserSubject;
  }

  get refreshTokenPeriod() {
    return this._refreshTokenPeriod;
  }

  set refreshTokenPeriod(period: number) {
    this._refreshTokenPeriod = period;
  }

  get domain() {
    return this.currentUserSubject.value?.client?.clientDomainId;
  }

  isDomain(clientDomainEnum: ClientDomainEnum): boolean {
    return this.domain === clientDomainEnum;
  }

  isLogged() {
    const token = this.currentUserSubject.value?.token;
    return !_.isEmpty(this.currentUserSubject.value) && !jwtHelper.isTokenExpired(token);
  }

  isLoggedOauth2() {
    const token = this.currentJwtSubject.value?.access_token;
    return !_.isEmpty(this.currentJwtSubject.value) && !jwtHelper.isTokenExpired(token);
  }

  auth(username: string, password: string, domain: string) {
    return this.http
      .post<any>(`${environment.backendUrl}/login/signin`, {
        email: username,
        password: password,
        domain: domain
      })
      .pipe(switchMap((token: Token) => this.getUsersInfo(token.accessToken).pipe(map(user => [token, user]))))
      .pipe(
        map(data => {
          const token = data[0];
          const userData = data[1];
          const user: User = userData;
          user.token = token.accessToken;
          if (user.client['id']) {
            user.client.clientId = user.client['id'];
          }
          if (userData.client.name) {
            user.client.clientName = userData.client.name;
          }
          const refreshTokenPeriod = token.tokenRefreshWindowTime;
          if (refreshTokenPeriod) {
            this.refreshTokenPeriod = refreshTokenPeriod;
          }
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  authOatuh2(token: JWT) {
    if (token.access_token) {
      localStorage.setItem('token', JSON.stringify(token));
      this.currentJwtSubject.next(token);
    }
  }

  login(username: string, password: string) {
    return this.http
      .post<any>(`${environment.backendUrl}/login/signin`, {
        email: username,
        password
      })
      .pipe(
        map(loginData => {
          return this.getUsersInfo(loginData.accessToken).pipe(
            map(userData => {
              const user: User = userData;
              user.token = loginData.accessToken;
              if (user.client['id']) {
                user.client.clientId = user.client['id'];
              }
              // user.client.clientId = user.client['id'];
              if (userData.client.name) {
                user.client.clientName = userData.client.name;
              }
              localStorage.removeItem('currentUser');
              localStorage.setItem('currentUser', JSON.stringify(user));
              this.currentUserSubject.next(user);
              return user;
            })
          );
        })
      )
      .pipe(first());
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    this.httpRequestCache.clear(); // borrar cache

    this.currentUserSubject.next(null as unknown as User);
    this.currentJwtSubject.next(null as unknown as JWT);

    if (DomainService.getDomain() === DomainsEnum.HISPASAT) {
      this.router.navigate(['/login']);
    }

    if (DomainService.getDomain() === DomainsEnum.AXESS) {
      this.router.navigate(['/login']);
    }
  }

  clearLocalStorage(patterns: string[]) {
    const keysToRemove = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && patterns.some(pattern => key.includes(pattern))) {
        keysToRemove.push(key);
      }
    }
    keysToRemove.forEach(key => localStorage.removeItem(key));
  }

  clearSessionStorage(patterns: string[]) {
    const keysToRemove = [];
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      if (key && patterns.some(pattern => key.includes(pattern))) {
        keysToRemove.push(key);
      }
    }
    keysToRemove.forEach(key => sessionStorage.removeItem(key));
  }

  refreshToken() {
    if (this.currentUser.value.token) {
      const refreshTokenSub = this.http.post<any>(
        `${environment.backendUrl}/login/refresh-token`,
        { token: this.currentUser.value.token },
        {
          observe: 'body',
          responseType: 'json'
        }
      );
      refreshTokenSub.subscribe((loginData: any) => {
        this.currentUserSubject.next({ ...this.currentUser.value, token: loginData.accessToken });
        localStorage.setItem('currentUser', JSON.stringify(this.currentUser.value));
        this.currentUserSubject.next(this.currentUser.value);
      });
      return refreshTokenSub;
    }
    throw new Error('Refresh token.');
  }

  getUsersInfo(token: string) {
    const headers = new HttpHeaders().append('Authorization', `Bearer ${token}`);
    return this.http.get<any>(environment.backendUrl + '/users/info', {
      headers
    });
  }

  getTokenAzure() {
    return this.http
      .get<any>(`${environment.backendUrl}/azure/token`)
      .pipe(switchMap((token: Token) => this.getUsersInfo(token.accessToken).pipe(map(user => [token, user]))))
      .pipe(
        map(data => {
          const token = data[0];
          const userData = data[1];
          const user: User = userData;
          user.token = token.accessToken;
          if (user.client['id']) {
            user.client.clientId = user.client['id'];
          }
          if (userData.client.name) {
            user.client.clientName = userData.client.name;
          }
          const refreshTokenPeriod = token.tokenRefreshWindowTime;
          if (refreshTokenPeriod) {
            this.refreshTokenPeriod = refreshTokenPeriod;
          }
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  loginRedirectAzure() {
    window.location.href = this.getUrlOauth2Login();
  }

  logoutRedirectAzure() {
    const logoutAzure = environment.azure_logout + '?post_logout_redirect_uri=';
    window.location.href = logoutAzure + this.getUrlOauth2Login();
  }

  private getUrlOauth2Login(): string {
    return this.getOauth2Domain() + '/oauth2/authorization/B2C_1_Axess_Login';
  }

  private getOauth2Domain(): string {
    let domain: string = environment.backendUrl;
    if (!domain.startsWith('https')) {
      domain = environment.backendUrl;
    }
    return domain;
  }

  checkClientTypeRole(minRole: ClientTypeEnum) {
    if (!this.currentUser.value?.client || !minRole) {
      return false;
    }
    const userRole = this.currentUser.value.client.clientType.code;
    return clientTypeWeight[userRole] <= clientTypeWeight[minRole];
  }

  checkClientTypeRoleMax(maxRole: ClientTypeEnum) {
    if (!this.currentUser.value?.client || !maxRole) {
      return false;
    }
    const userRole = this.currentUser.value.client.clientType.code;
    return clientTypeWeight[userRole] >= clientTypeWeight[maxRole];
  }

  checkUserRole(minRole: RoleEnum) {
    if (!this.currentUser.value?.role || !minRole) {
      return false;
    }
    const userRole = this.currentUser.value.role.code;
    return roleWightUser[userRole] <= roleWightUser[minRole];
  }

  checkUserRoleMax(maxRole: RoleEnum) {
    if (!this.currentUser.value?.role || !maxRole) {
      return false;
    }
    const userRole = this.currentUser.value.role.code;
    return roleWightUser[userRole] >= roleWightUser[maxRole];
  }

  checkUserOnlyRole(role: RoleEnum) {
    if (!this.currentUser.value?.role || !role) {
      return false;
    }
    const userRole = this.currentUser.value.role.code;
    return role === userRole;
  }

  checkUserOnlyTypeClientRole(role: ClientTypeEnum) {
    if (!this.currentUser.value?.client || !role) {
      return false;
    }
    const clientTypeRole = this.currentUser.value.client.clientType.code;
    return role === clientTypeRole;
  }

  checkClientTypeRoleNotAdmin() {
    return (
      this.currentUser.value.client.clientType.code !== ClientTypeEnum.ROLE_CLIENT_ADMIN_DOMAIN &&
      this.currentUser.value.client.clientType.code !== ClientTypeEnum.ROLE_CLIENT_ADMIN
    );
  }

  setLanguage(language: BaseDto) {
    const user = { ...this.currentUser.value, language: language };
    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  isClientTypeRoleAdmin() {
    return (
      this.currentUser.value?.client?.clientType?.code === ClientTypeEnum.ROLE_CLIENT_ADMIN_DOMAIN ||
      this.currentUser.value?.client?.clientType?.code === ClientTypeEnum.ROLE_CLIENT_ADMIN
    );
  }

  getClientTypeUserLogged() {
    return this.currentUser.value.client.clientType.code;
  }

  getRolUserLogged() {
    return this.currentUser.value.role?.code;
  }
}

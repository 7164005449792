/* eslint-disable */
export enum ActionsTitleEnum {
  'router-wifi-blue' = 'COMMONS.ROUTER_WIFI',
  'terminal-wifi-blue' = 'COMMONS.TERMINAL_WIFI',
  'delete-blue' = 'COMMONS.DELETE',
  'edit-blue' = 'COMMONS.EDIT',
  'monitoring-blue' = 'COMMONS.MONITORING',
  'check-blue' = 'COMMONS.CHECK',
  'add-blue' = 'COMMONS.ADD',
  'confirm-blue' = 'COMMONS.CONFIRM_OPTION',
  'share-blue' = 'COMMONS.SHARE',
  'historic-blue' = 'COMMONS.HISTORIC',
  'assignment' = 'COMMONS.ASSIGMENT',
  'search-blue' = 'COMMONS.SEE',
  'forward-blue' = 'COMMONS.EXECUTE',
  'download-blue' = 'COMMONS.DOWNLOAD',
  'direccion-ip-blue' = 'COMMONS.IP',
  'receptor-blue' = 'COMMONS.TERMINAL',
  'undo-blue' = 'COMMONS.UNDO',
  'eye-blue' = 'COMMONS.EYE',
  'cogs' = 'COMMONS.PHYSICAL_ID_ADD',
  'unblocked-blue' = 'COMMONS.UNBLOCKED',
  'play2-blue' = 'COMMONS.ACTIVATE',
  'force-activation-blue' = 'COMMONS.PHYSICAL_ID_ADD',
  'rf_cluster' = 'COMMONS.RF_CLUSTER',
  'network_segment' = 'COMMONS.NETWORK_SEGMENT',
  'kmz' = 'COMMONS.KMZ',
  'sqfmin' = 'COMMONS.SQFMIN',
  'change-platform-blue' = 'COMMONS.CHANGE_PLATFORM',
  'qts-blue' = 'COMMONS.QTS_BEAM_CONF',
  'serial-number-blue' = 'COMMONS.OLD_SERIAL_NUMBER',
  'provisional-delete-blue' = 'COMMONS.REMOVE_TERMINAL',
  'perma-delete-blue' = 'COMMONS.PERMA_REMOVE'
}
